/* eslint-disable react/jsx-pascal-case */
import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BackTopButton from "../../components/back-top-button"

const PrivacyTemplate = ({ data }) => {
  const page = data.mdx

  return (
    <Layout>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description}
      />

      <MDXRenderer>{page.body}</MDXRenderer>
      <BackTopButton></BackTopButton>
    </Layout>
  )
}

export default PrivacyTemplate

export const privacyPageQuery = graphql`
  query PrivacyPageBySlug($language: String!, $id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
